@use '@/styles/utils/mixins.scss' as *;

.pageContentWrapper {
  .pageContentContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;

    @include tab() {
      grid-template-columns: repeat(1, 1fr);
      gap: 4rem;
    }
  }
  .pageContentDetails {
    width: 100%;
    grid-column: span 2;
    @include tab() {
      grid-column: span 1;
    }

    .descriptionWrapper {
      margin-top: 1.25rem;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin-bottom: 1.5rem;
        font-weight: 500;
        &:not(:first-child) {
          margin-top: 2.5rem;
        }
      }
      h1 {
        font-size: 6rem;
      }
      h2 {
        font-size: 4.5rem;
      }
      h3 {
        font-size: 3rem;
      }
      h4 {
        font-size: 2.125rem;
      }
      h5 {
        font-size: 1.5rem;
        @include mob() {
          font-size: 1rem;
        }
      }
      h6 {
        font-size: 1.5rem;
        @include mob() {
          font-size: 1rem;
        }
      }

      p {
        margin-bottom: 1rem;
      }
      ul,
      ol {
        padding-left: 1.5rem;
        margin-bottom: 1rem;
      }
      a {
        color: $primary;
        &:hover,
        &:focus,
        &:active {
          color: $primaryAlt;
        }
      }
    }
    .imageWrapper {
      overflow: hidden;
      border-radius: 1.25rem;
      width: 100%;
      height: 21.75rem;
      flex: 0 0 auto;
      margin-top: 3.75rem;

      @include tab() {
        height: 25.625rem;
        margin-top: 5rem;
      }
      @include mob() {
        width: calc(100% + 3.75rem);
        margin: 0 -1.875rem;
        margin-top: 5rem;
      }
      .pageContentImage {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
    .pageContentImage {
      width: 100%;
    }
  }
}
